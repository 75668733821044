import React from 'react'

const LogosVersion2 = () => {
  
  return (
    <div className='max-w-[211px] h-fit bg-white text-black flex items-center rounded-md'>
        <img src="https://ik.imagekit.io/238r9emirjj/LinkdinUsers/Group%205611.png?updatedAt=1703682751485" alt="" className='w-full h-auto object-cover' />
    </div>
  )
}

export default LogosVersion2