import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"



export const fetchWalkthroughData = createAsyncThunk(
    'walkthrough/fetchWalkthroughData',
    async ({ email }) => {
        const response = await axios.get('https://xgfy-czuw-092q.m2.xano.io/api:ZZCtun0K/walkthrough_status', {
            params: {
                email: email
            }
        })
        return response.data
    }
)

export const postWalkthroughData = createAsyncThunk(
    'walkthrough/postWalkthroughData',
    async ({ email }) => {
        const response = await axios.get('https://xgfy-czuw-092q.m2.xano.io/api:ZZCtun0K/walkthrough_status/update', {
            params: {
                email: email
            }
        })
        return response.data
    }
)

const walkthroughSlice = createSlice({
    name: 'walkthrough',
    initialState: {},
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchWalkthroughData.fulfilled, (state, action) => {
            return action.payload
        })
    }
})

export const selectWalkthroughData = (state) => state.walkthrough
export default walkthroughSlice.reducer