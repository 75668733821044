import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const fetchSavedCourse = createAsyncThunk(
    'savedCourseSlice/fetchSavedCourse',
    async ({ uuid }) => {      
        try {
            const response = await axios.get('https://xgfy-czuw-092q.m2.xano.io/api:ZZCtun0K/fetch_saved_course', { params: {
                uuid: uuid
            }});
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }
);

const initialState = {
    uuid: "",
    old_uuid: "",
    courseDetails: {}
}
const savedCourseSlice = createSlice({
    name: 'savedCourse',
    initialState,
    reducers: {
        setSavedUuid: (state, action) => {
            state.uuid = action.payload
        },
        resetSavedDetails: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchSavedCourse.fulfilled, (state, action) => {
            state.old_uuid = action.payload.old_uid.uuid
            state.courseDetails = action.payload.saved_course
        })
    },
});

export const { setSavedUuid, resetSavedDetails } = savedCourseSlice.actions
export const selectSavedCourseDetails = (state) => state.savedCourse.courseDetails
export const selectOldUserUuid = (state) => state.savedCourse.old_uuid
export const selectSavedUserUuid = (state) => state.savedCourse.uuid
export default savedCourseSlice.reducer;