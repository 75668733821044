import React from 'react'

const Logos = () => {
  
  return (
    <div className='max-w-[211px] h-auto mt-4 lg:mt-6 flex items-center justify-between mx-auto rounded-md mb-4'>
      <img src="https://ik.imagekit.io/238r9emirjj/LinkdinUsers/Group%205611.png?updatedAt=1703682751485" alt="" className='w-full h-auto' />
    </div>
  )
}

export default Logos