import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

const POLLING_INITIAL_DELAY = 1*60*1000;
const POLLING_INTERVAL = 3*60*1000

const pollAPI = async ({ user_id, course_name }) => {
    try {
        const response = await axios.post('https://ninjacurate.learntube.ai/curate_status', { user_id: user_id, subject_name: course_name });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchCuratedCourse = createAsyncThunk(
    'curatedCourse/fetchCuratedCourse',
    async ({ user_id, course_name }) => {      
        try {
            await new Promise(resolve => setTimeout(resolve, POLLING_INITIAL_DELAY));
            while (true) {
                const response = await pollAPI({ user_id, course_name });
                if (response?.status === 'completed') {
                    return response;
                }
            await new Promise(resolve => setTimeout(resolve, POLLING_INTERVAL));
        }
        } catch (error) {
            return error?.message;
        }
    }
);

const initialState = {
    status: ''
}

const curatedCourseSlice = createSlice({
    name: 'curatedCourse',
    initialState,
    reducers: {
        resetCurationStatus: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchCuratedCourse.fulfilled, (state, action) => {
            return action.payload
        })
    },
});

export const { resetCurationStatus } = curatedCourseSlice.actions
export const selectCuratedCourse = (state) => state.curatedCourse;
export default curatedCourseSlice.reducer;