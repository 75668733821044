import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// Action to fetch questions asynchronously
export const fetchQuestions = createAsyncThunk(
    'questions/fetch',
    async ({ skillName, skillLevel, utm_source, utm_medium, utm_campaign }) => {
        const maxRetries = 3;
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axios.post('https://ninjacurate.learntube.ai/q_generation', { skill_name:skillName, skill_difficulty:skillLevel, utm_source:utm_source, utm_medium:utm_medium, utm_campaign: utm_campaign });
                if (response.data === true || response.data === false) {
                    const navigate = useNavigate()
                    navigate('/')
                    return;
                }
                return response.data;
            } catch (err) {
                console.log(err);
                retries += 1;
            }
    }
        toast.error('Error in fetching questions. Please try again later.');
    });

export const fetchMCQuestions = createAsyncThunk(
    'questions/fetchMCQuestions',
    async({ skill_name, name, skill_difficulty, utm_source, utm_medium, utm_campaign }) => {
        const maxRetries = 3
        let retries = 0
        while (retries < maxRetries) {
            try {
                const response = await axios.post('https://ninjacurate.learntube.ai/q_generation_mcq_base', {
                    skill_name, name, skill_difficulty, utm_source, utm_medium, utm_campaign
                })
                return response.data
            } catch (error) {
                console.log(error.message)
                retries += 1
            }
        }
    }
)

const initialState = {
    uuid: '',
    question_list: [],
    mcq_list: [],
    answers: []
}
// Redux slice for questions
const questionsSlice = createSlice({
    name: 'questions',
    initialState,
    reducers: {
        resetQuestions: (state) => initialState,
        setUuid: (state, action) => {
            state.uuid = action.payload
        },
        setAnswers: (state, action) => {
            const { answer, index } = action.payload
            if (state?.answers[index]){
                state.answers[index] = answer
            }else {
                state?.answers.push(answer)
            }
        },
        
    },
    extraReducers: (builder) => {
        builder.addCase(fetchQuestions.fulfilled, (state, action) => {
            const { uuid, question_list } = action.payload
            state.uuid = uuid
            state.question_list = question_list
        });
        builder.addCase(fetchMCQuestions.fulfilled, (state, action) => {
            const { questionaire, uuid } = action.payload
            state.uuid = uuid
            state.mcq_list = questionaire
        })
    },
});


export const { resetQuestions, setUuid, setAnswers } = questionsSlice.actions
export const selectQuestions = (state) => state.questions.question_list;
export const selectMCQuestions = (state) => state.questions.mcq_list
export const selectAnswers = (state) => state.questions.answers
export const uuidNo = (state) => state.questions.uuid;
export default questionsSlice.reducer;