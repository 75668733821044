/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Logos from './Logos'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectLastVisited } from '../redux/nameSlice'

const FallbackUI = ({ resetError }) => {

    const [ seconds, setSeconds ] = useState(5)
    const navigate = useNavigate()

    const lastVisitedUrl = useSelector(selectLastVisited)

    useEffect(() => {
        const timeId = setTimeout(() => setSeconds(prev => prev - 1), 1000)
        if(seconds === 0){
            clearTimeout(timeId)
            resetError()
            navigate(lastVisitedUrl)
        }
        return () => clearInterval(timeId)
    }, [seconds, navigate])

    return (
        <div className={`w-full mx-auto h-[100dvh] flex flex-col overflow-y-scroll`} style={{background: 'radial-gradient(50% 50% at 50% 50%, #26496A 0%, #182C3F 100%)'}}>
            <Logos />
            <div className='max-w-3xl w-full px-4 pb-4 md:px-6 md:pb-6 text-white mx-auto flex-1 flex text-center flex-col items-center justify-center'>
                <div className='w-full md:w-2/5'>
                    <img src="https://ik.imagekit.io/238r9emirjj/CertificateVerification/something-went-wrong%201.png?updatedAt=1703164758895" alt="" />
                </div>
                <h1 className='text-2xl -mt-4 md:-mt-8'>Oops! Something <span className='text-[#00C1FF] font-bold'>Went Wrong</span></h1>
                <p>We’re sorry an unknown error occurred. We’re redirecting you to home...</p>
                <p className='mt-4'>Redirecting In</p>
                <h1 className='text-[100px] -mt-3 lg:text-[70px] font-bold'>{seconds}</h1>
            </div>
        </div>
    )
}

export default FallbackUI