import React from 'react'

const SearchSkeleton = () => {
    return (
        <div className="">
            <div className="w-full h-[45px] bg-[#2D4F71] border-b border-[#3572AF] animate-pulse"/>
            <div className="w-full h-[45px] bg-[#2D4F71] border-b border-[#3572AF] animate-pulse"/>
            <div className="w-full h-[45px] bg-[#2D4F71] border-b border-[#3572AF] animate-pulse"/>
            <div className="w-full h-[45px] bg-[#2D4F71] border-b border-[#3572AF] animate-pulse"/>
            <div className="w-full h-[45px] bg-[#2D4F71] border-b border-[#3572AF] animate-pulse"/>
        </div>
    )
}

export default SearchSkeleton