import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchPopularSkills = createAsyncThunk(
    'fetchPopularSkills/searchPopularSkills',
    async ({ searchValue }) => {
        const response = await axios.post('https://ninjacurate.learntube.ai/fast_search',
            {
                skill: searchValue
            })

        return response.data

    }
)

const searchPopularSkillSlice = createSlice({
    name: 'searchPopularSkill',
    initialState: [],
    reducers: {
        selectSearchResult: (state, action) => {
            return [action.payload]
        }
    },
    extraReducers: (builder)=> {
        builder
        .addCase(fetchPopularSkills.fulfilled, (state, action) => {
            return action.payload
        })
    }
})

export const selectSearchResult = state => state.searchPopularSkill 
export default searchPopularSkillSlice.reducer