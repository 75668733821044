import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosAuth } from "../config/axiosInstance";

export const fetchModuleProgress = createAsyncThunk(
    'progress/fetchModuleProgress',
    async ({uid, subject_name, module_name }) => {      
        try {
            const response = await axiosAuth.post('https://ninjacurate.learntube.ai/fetch_module_progress', { uid: uid, subject_name: subject_name, module_name: module_name});
            return response.data;
        } catch (err) {
            console.log(err);
        }
  }
);

export const fetchCourseProgress = createAsyncThunk(
    'progress/fetchCourseProgress',
    async ({ uid, subject_name }) => {      
        try {
            const response = await axiosAuth.post('https://ninjacurate.learntube.ai/fetch_course_progress', { uid: uid, subject_name: subject_name });
            return response.data;
        } catch (err) {
            console.log(err);
        }
  }
);

const initialState = {
    moduleProgress: 0,
    courseProgress: 0
}

const progressSlice = createSlice({
    name: 'progress',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCourseProgress.fulfilled, (state, action) => {
            return {
                ...state,
                courseProgress: action.payload
            };
        });
        builder.addCase(fetchModuleProgress.fulfilled, (state, action) => {
            return {
                ...state,
                moduleProgress: action.payload
            };
        });
    },
});

export const selectModuleProgress = (state) => state.progress.moduleProgress;
export const selectCourseProgress = (state) => state.progress.courseProgress;
export default progressSlice.reducer;