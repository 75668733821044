import { createSlice } from '@reduxjs/toolkit';

const timerSlice = createSlice({
    name: 'timer',
    initialState: {
        startTime: null,
    },
    reducers: {
        setStartTime: (state, action) => {
        state.startTime = action.payload;
        },
    },
});


export const { setStartTime } = timerSlice.actions;
export default timerSlice.reducer;
