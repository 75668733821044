import { createSlice } from '@reduxjs/toolkit';



// Redux slice for questions
const questionAnswersSlice = createSlice({
    name: 'questionAnswer',
    initialState: [],
    reducers: {
        addQuestionAnswer: (state, action) => { 
            return [...state, action.payload]
        },
        resetQuestionAnswer: (state) => []
    },
});

export const { addQuestionAnswer, resetQuestionAnswer } = questionAnswersSlice.actions
export const selectQuestionAnswer = (state) => state.questionAnswers;
export default questionAnswersSlice.reducer;