import { Navigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectAuthentication } from '../redux/authenticationSlice'

export default function PrivateRoute({ children }) {

    const token = useSelector(selectAuthentication)?.token
    const location = useLocation()

    return token ? children : <Navigate to={"/welcome"} replace state={{ from: location }} />
}