import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosAuth } from "../config/axiosInstance";

export const fetchLessonDetails = createAsyncThunk(
    'lessonDetails/fetchLessonDetails',
    async ({ uid, subject, lesson_slug }) => {      
        try {
            const response = await axiosAuth.post('https://ninjacurate.learntube.ai/fetch_lesson_data', {uid: uid, subject: subject, lesson_slug: lesson_slug });
            return response.data;
        } catch (err) {
            console.log(err)
        }
    }
);

const initialState = {}

const lessonDetailsSlice = createSlice({
    name: 'lessonDetails',
    initialState,
    reducers: {
        resetLessonDetails: (state) => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(fetchLessonDetails.fulfilled, (state, action) => {
            return action.payload;
        });
    },
});

export const { resetLessonDetails } = lessonDetailsSlice.actions
export const selectLessonDetails = (state) => state.lessonDetails;
export default lessonDetailsSlice.reducer;