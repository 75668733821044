import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const fetchSuccessStories = createAsyncThunk(
    'successStoriesAndReviewSlice/fetchSuccessStories',
    async () => {      
        try {
            const response = await axios.get('https://xgfy-czuw-092q.m2.xano.io/api:HYL0rVog/user_stories_and_reviews');
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }
);

export const fetchReviews = createAsyncThunk(
    'successStoriesAndReviewSlice/fetchReviews',
    async () => {      
        try {
            const response = await axios.get('https://xgfy-czuw-092q.m2.xano.io/api:HYL0rVog/pricing_page/reviews');
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }
);

const initialState = {
    successStories: {},
    reviewStories: {}
}
const successStoriesAndReviewSlice = createSlice({
    name: 'successStoriesAndReviews',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchSuccessStories.fulfilled, (state, action) => {
            state.successStories = action.payload?.data.success_stories
        })
        .addCase(fetchReviews.fulfilled, (state, action) => {
            state.reviewStories = action.payload
        })
    },
});


export const selectSuccessStories = (state) => state.successStoriesAndReviews.successStories;
export const selectReviews = (state) => state.successStoriesAndReviews.reviewStories;
export default successStoriesAndReviewSlice.reducer;