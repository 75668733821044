import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosAuth } from "../config/axiosInstance";

export const dispatchScholarshipData = createAsyncThunk(
  'scholarship/dispatchScholarshipData',
    async ({ uid, subject_name }) => {      
        try {
            const response = await axiosAuth.post('https://ninjacurate.learntube.ai/update_scholarship_data', { uid: uid, subject_name: subject_name });
            return response.data;
        } catch (err) {
            console.log(err);
            throw err; // Rethrow the error so it can be handled in the component
        }
    }
);

const initialState = {
    minutes: 0,
    seconds: 0,
    timeExpired: false,
    appliedAt: 0,
    result: null
}

const scholarshipSlice = createSlice({
    name: 'scholarshipDetails',
    initialState, // Set initial state as null if you don't want to store any initial data
    reducers: {
        setTimer: (state, action) => {
            const { minutes, seconds } = action.payload
            state.minutes = minutes
            state.seconds = seconds
        },
        setTimeExpired: (state, action) => {
            state.timeExpired = action.payload
        },
        setCreatedAt: (state, action) => {
            state.appliedAt = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(dispatchScholarshipData.fulfilled, (state, action) => {
            return {
                ...state,
                result: action.payload
            };
        });
    },
});

export const selectScholarshipData = (state) => state.scholarshipDetails; // Correct the selector to 'scholarship'
export const { setTimeExpired, setTimer, setCreatedAt } = scholarshipSlice.actions
export default scholarshipSlice.reducer;
