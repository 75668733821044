import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// Action to fetch questions asynchronously
export const fetchMCQ = createAsyncThunk(
    'mcQuestions/fetch',
    async ({ uid, skill_name, skill_difficulty }) => {
        const maxRetries = 3;
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axios.post('https://ninjacurate.learntube.ai/q_generation_onboarding', { uid: uid, skill_name: skill_name, skill_difficulty: skill_difficulty });
                if (response.data === true || response.data === false || !Array.isArray(response.data?.questionaire)) {
                    const navigate = useNavigate()
                    navigate('/')
                    return;
                }
                return response.data;
            } catch (err) {
                console.log(err);
                retries += 1;
            }
    }
        toast.error('Error in fetching questions. Please try again later.');
})

export const fetchMCQAnalysis = createAsyncThunk(
    'mcQuestions/fetchMCQAnalysis',
    async ({ user_id, subject, questions, correct_answers, user_answers }) => {
        const maxRetries = 3;
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axios.post('https://certified-backend.learntube.ai/mcq_analysis', {
                    user_id:user_id, subject: subject, questions: questions, correct_answers: correct_answers, user_answers: user_answers
                })
                return response.data
            } catch (e) {
                console.log(e.message)
                retries += 1;
            }
        }
    }
)

export const fetchMCQCourse = createAsyncThunk(
    'mcQuestions/fetchMCQCourse',
    async ({ uid, subject, email }) => {
        const maxRetries = 3;
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axios.post('https://certified-backend.learntube.ai/course_onboarding', {user_id:uid, subject: subject, email: email})
                return response.data
            } catch (e) {
                console.log(e.message)
                retries += 1;
            }
        }
    }
)

export const fetchPaymentStatus = createAsyncThunk(
    'mcQuestions/fetchPaymentStatus',
    async ({ uid }) => {
        try {
            const response = await axios.get('https://xgfy-czuw-092q.m2.xano.io/api:ZZCtun0K/fetch_payment_status', {params: {
                user_id: uid
            }})
            return response.data
        } catch (e) {
            console.log(e.message)
        }
    }
)

 


const initialState = {
    questionaire: [],
    answers: [],
    analysis: {},
    score: 0,
    course: {},
    email: "",
    paymentStatus: ""
}
// Redux slice for questions
const mcQuestionsSlice = createSlice({
    name: 'mcQuestions',
    initialState,
    reducers: {
        resetMCQuestions: (state) => initialState,
        setAnswers: (state, action) => {
            const { answer, index } = action.payload
            if (state?.answers[index]){
                state.answers[index] = answer
            }else {
                state?.answers.push(answer)
            }
        },
        setScore: (state, action) => {
            state.score = action.payload
        },
        setEmail: (state, action) => {
            state.email = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMCQ.fulfilled, (state, action) => {
            state.questionaire = action.payload?.questionaire
        });
        builder.addCase(fetchMCQAnalysis.fulfilled, (state, action) => {
            state.analysis = action.payload
        });
        builder.addCase(fetchMCQCourse.fulfilled, (state, action) => {
            state.course = action.payload
        });
        builder.addCase(fetchPaymentStatus.fulfilled, (state, action) => {
            state.paymentStatus = action.payload
        });
    },
});


export const { resetMCQuestions, setAnswers, setScore, setEmail } = mcQuestionsSlice.actions
export const selectMCQuestions = (state) => state.mcQuestions?.questionaire
export const selectAnswers = (state) => state.mcQuestions?.answers
export const selectMCQAnalysis = (state) => state.mcQuestions?.analysis
export const selectMCQCourse = (state) => state.mcQuestions?.course
export const selectScore = (state) => state.mcQuestions?.score
export const selectEmail = (state) => state.mcQuestions?.email
export const selectMCQCurationStatus = (state) => state.mcQuestions?.course?.is_curation
export const selectPaymentStatus = (state) => state.mcQuestions?.paymentStatus?.status
export default mcQuestionsSlice.reducer;