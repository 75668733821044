import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const fetchCourseStatus = createAsyncThunk(
    'beforePayment/fetchCourseStatus',
    async ({ user_name, user_uuid, course_name, skill_difficulty, user_email }) => {  
        let retries = 0
        while (retries < 3){
            try {
                const response = await axios.post('https://ninjacurate.learntube.ai/course_before_payment', { user_name: user_name, user_uuid: user_uuid, course_name: course_name, skill_difficulty: skill_difficulty, user_email: user_email })
                if (response.status === 200){
                    return response.data
                }
            } catch (err) {
                retries += 1
                console.log(err)
            }
        }
    }
);

const initialState = {
    is_curation: null,
    course_uid: '',
    course_show_data: {}
}

const beforePaymentSlice = createSlice({
    name: 'beforePayment',
    initialState,
    reducers: {
        resetXanoData: (state) => initialState,
        setCurationStatus: (state, action) => {
            console.log('I was called')
            const { is_curation, course_uid } = action.payload
            console.log(is_curation, course_uid)
            state.is_curation = is_curation
            state.course_uid = course_uid
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchCourseStatus.fulfilled, (state, action) => {
            return action.payload
        })
    },
});

export const { resetXanoData, setCurationStatus } = beforePaymentSlice.actions
export const selectBeforePaymentCourseDetails = (state) => state.beforePayment;
export const selectCurationStatus = (state) => state.beforePayment.is_curation;
export default beforePaymentSlice.reducer;