import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosAuth } from "../config/axiosInstance";

export const fetchKeyTakeaways = createAsyncThunk(
    'keyTakeaway/fetchKeyTakeaways',
    async ({video_id, goal, skill, user_id, lesson_id, subject }) => {      
        try {
          const response = await axiosAuth.post('https://ninjacurate.learntube.ai/keypoints', {user_id: user_id, video_id:video_id, goal:goal, skill:skill, lesson_id: lesson_id, subject: subject });
          return response.data;
        } catch (err) {
          console.log(err);
        }
  }
);

const keyTakeawaySlice = createSlice({
    name: 'keyTakeaway',
    initialState: {},
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchKeyTakeaways.fulfilled, (state, action) => {
            return action.payload;
        });
    },
});

export const selectKeyTakeaway = (state) => state.keyTakeaway;
export default keyTakeawaySlice.reducer;