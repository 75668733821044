import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosAuth } from "../config/axiosInstance";

export const fetchChats = createAsyncThunk(
    'askCreator/fetchChats',
    async ({ user_id, lesson_id, course_name }) => {      
        try {
            const response = await axiosAuth.post('https://ninjacurate.learntube.ai/fetch_chat_history', { user_id:user_id, lesson_id:lesson_id, course_name: course_name });
            return response.data;
        } catch (err) {
            console.log(err);
        }
  }
);

export const fetchLastChat = createAsyncThunk(
    'askCreator/fetchLastChat',
    async ({ user_id, video_id, lesson_id, query, is_voice, course_name }, { dispatch }) => {      
        try {
            const response = await axiosAuth.post('https://ninjacurate.learntube.ai/chat_async', { user_id:user_id, video_id:video_id, lesson_id:lesson_id, query:query, is_voice: is_voice, course_name: course_name });
            dispatch(fetchLastChatVoiceResponse(response.data))
            return response.data;
        } catch (err) {
            console.log(err);
        }
  }
);

export const fetchLastChatVoiceResponse = createAsyncThunk(
    'askCreator/fetchLastChatVoiceResponse',
    async ( dataFromLastChat ) => {
        try {
            const { uai } = dataFromLastChat
            const response = await axiosAuth.post('https://ninjacurate.learntube.ai/fetch_audio_file', { uai: uai })
            return response.data
        } catch (err) {
            console.log(err.message);
            throw err
        }
    }
)

const askCreatorSlice = createSlice({
    name: 'askCreator',
    initialState: {
      uai: null,
      chat_history: [],
      loading: false,
      loadingMessage: false,
      limit_reached: false
    },
    reducers: {
        addLatestQuestion: (state, action)=>{
            state.chat_history = [...state.chat_history, action?.payload]
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchChats.fulfilled, (state, action) => {
            return {
                ...state,
                chat_history: action?.payload?.chat_history
            }
        })
        .addCase(fetchLastChat.pending, (state, action) => {
            state.loadingMessage = true
        })
        .addCase(fetchLastChat.fulfilled, (state, action) => {
            let response
            if (action?.payload?.limit_reached){
                response = {role: "system", content: "You have reached your daily limit of 5 doubts. To unlock unlimited doubt solving upgrade to LearnTubeX."}
            } else {
                response = {role: "system", content: action?.payload?.response}
            }
            return {
                ...state,
                loadingMessage: false,
                limit_reached: action?.payload?.limit_reached,
                uai: action?.payload?.uai,
                chat_history: [...state?.chat_history, response]
            }
        })
        .addCase(fetchLastChatVoiceResponse.pending, (state) => {
            return {
                ...state,
                loading: true
            }
        })
        .addCase(fetchLastChatVoiceResponse.fulfilled, (state, action) => {
            let response = {role: "system", content: action?.payload?.file_url}
            return {
                ...state,
                loading: false,
                chat_history: [...state?.chat_history, response]
            }
        })
        .addCase(fetchLastChatVoiceResponse.rejected, (state) => {
            state.loading = false;
        });
    },
});

export const { addLatestQuestion } = askCreatorSlice.actions
export const selectChats = (state) => state.askCreator;
export default askCreatorSlice.reducer;