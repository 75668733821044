import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const fetchToken = createAsyncThunk(
    'askCreator/fetchToken',
    async ({ email_id }) => {      
        try {
            const response = await axios.post('https://ninjacurate.learntube.ai/user_authenticate', { user_email: email_id });
            return response.data;
        } catch (err) {
            console.log(err);
        }
  }
);

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: {
        email: '',
        userName: '',
        userPhone: ''
    },
    reducers: {
        logoutUser(state) {
            state.token = null;
            // Other state cleanup related to logout
        },
        setUserEmail(state, action) {
            state.email = action.payload;
        },
        setAuthenticationError(state, action) {
            state.error = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchToken.fulfilled, (state, action) => {
            if (action.payload.access_token){
                return {
                    ...state,
                    uid: action?.payload?.uid,
                    token: action?.payload?.access_token,
                    userName: action?.payload?.user_name,
                    userPhone: action?.payload?.user_phone
                }
           } else {
                return {
                    ...state,
                    error: action?.payload?.Error
                }
           }
        })
    },
});

export const { logoutUser, setUserEmail, setAuthenticationError } = authenticationSlice.actions
export const selectAuthentication = (state) => state.authentication;
export default authenticationSlice.reducer;