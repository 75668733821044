import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    name: "",
    isScholarshipApplied: false,
    lastPlayedLesson: 0,
    analysisPageButton: false,
    lastVisited: ''
}

const nameSlice = createSlice({
    name: "name",
    initialState,
    reducers: {
        setUserName: (state, action) => {
            const { name } = action.payload;
            state.name = name; // Update the 'name' field in the state
        },
        setScholarshipDetails: (state, action) => {
            const { scholarship } = action.payload;
            state.isScholarshipApplied = scholarship; // Update the 'isScholarshipApplied' field in the state
        },
        setLastWatchedLesson: (state, action) => {
            const { lessonId } = action.payload
            state.lastPlayedLesson = lessonId
        },
        setAnalysisPageButtonClicked: (state, action) => {
            state.analysisPageButton = action.payload
        },
        setLastVisitedUrl: (state, action) => {
            state.lastVisited = action.payload
        }
    },
});

export const { setUserName, setScholarshipDetails, setLastWatchedLesson, setAnalysisPageButtonClicked, setLastVisitedUrl } = nameSlice.actions;
export const selectUserName = (state) => state.name.name;
export const selectScholarship = (state) => state.name.isScholarshipApplied;
export const selectLastPlayedLesson = (state) => state.name.lastPlayedLesson
export const selectAnalysisPageButtonClicked = (state) => state.name.analysisPageButton
export const selectLastVisited = (state) => state.name.lastVisited
export default nameSlice.reducer;
