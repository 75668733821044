import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

export const fetchUserDetailsFromOnboarding = createAsyncThunk(
    'fetchUserDetailsFromOnboarding/userDetailsFromOnboarding',
    async ({ uuid, isEven }) => {
        const response = await axios.get('https://xgfy-czuw-092q.m2.xano.io/api:ZZCtun0K/user_details/fetch_for_user', {
            params: {
                uid: uuid,
                is_even: isEven
            }
        })

        return response.data
    }
)

const initialState = {}

const userDetailsFromOnboardingSlice = createSlice({
    name: 'userDetailsFromOnboarding',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUserDetailsFromOnboarding.fulfilled, (state, action) => {
            return action.payload
        })
    }
})

export const selectUserDetailsFromOnboarding = (state) => state.userDetailsFromOnboarding
export default userDetailsFromOnboardingSlice.reducer