import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const fetchCourses = createAsyncThunk(
    'courses/fetch',
    async ({uuid, skillName, skillLevel, userKnows, userMissed}) => {
        const maxRetries = 2;
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axios.post('https://ninjacurate.learntube.ai/course_generate', {
                    uid:uuid, skill_name: skillName, skill_difficulty: skillLevel, user_knows: userKnows, user_missed: userMissed
                });
                if (response.data === true || response.data === false) {
                    const navigate = useNavigate()
                    navigate('/')
                    return;
                }
                return response.data;
            } catch (err) {
                console.log(err);
                retries += 1;
            }
        }
    }
)

const initialState = {}

const coursesSlice = createSlice({
    name: 'courses',
    initialState,
    reducers: {
        resetCourseData: (state) => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCourses.fulfilled, (state, action) => {
            return action.payload;
        });
    },
});

export const { resetCourseData } = coursesSlice.actions
export const selectCourses = (state) => state.courses;
export default coursesSlice.reducer;