import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosAuth } from "../config/axiosInstance";

export const fetchCourseModule = createAsyncThunk(
    'courseModule/fetchCourseModule',
    async ({ uid, subject_name }) => {      
        try {
            const response = await axiosAuth.post('https://ninjacurate.learntube.ai/fetch_user_course_data', { uid: uid, subject_name: subject_name });
            return response.data;
        } catch (err) {
            console.log(err);
        }
  }
);

const courseModuleSlice = createSlice({
    name: 'courseModule',
    initialState: {},
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCourseModule.fulfilled, (state, action) => {
            return action.payload;
        });
    },
});

export const selectCourseModule = (state) => state?.courseModule;
export const selectCourseModuleContent = (state) => state?.courseModule?.course_data
export default courseModuleSlice.reducer;