import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchSubscriptionStatus = createAsyncThunk(
    'subscriptionStatus/fetchSubscriptionStatus',
    async ({ email }) => {
        const response = await axios.get('https://xgfy-czuw-092q.m2.xano.io/api:ZZCtun0K/user_account_status', {
            params: {
                email: email
            }
        })
        return response.data
    }
)

const initialState = {
    is_locked: false,
    type: ''
}

const subscriptionStatusSlice = createSlice({
    name: 'subscriptionStatus',
    initialState,
    reducers: {
        setStatus: (state, action) => {
            state.is_locked = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSubscriptionStatus.fulfilled, (state, action) => {
            return action.payload
        })
    }
})

export const { setStatus } = subscriptionStatusSlice.actions
export const selectSubscriptionStatus = (state) => state.subscriptionStatus
export default subscriptionStatusSlice.reducer