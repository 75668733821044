import { Outlet, useLocation } from "react-router-dom";
import Logos from "../../components/Logos";
import React, {useEffect} from 'react';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { animateScroll as scroll } from 'react-scroll';
import { Line } from 'rc-progress';
import useWindowSize from "../../hooks/useWindowSize";

const Layout = () => {

    const { pathname } = useLocation();
    const { width } = useWindowSize();

    useEffect(() => {
        scroll.scrollToTop();
    }, []);
  
    
    return (
        <div  className={`w-full mx-auto h-[100dvh] flex flex-col overflow-y-scroll`} style={{background: 'radial-gradient(50% 50% at 50% 50%, #26496A 0%, #182C3F 100%)'}}>
            <ToastContainer />
            <Logos />
            <div className={`mt-4 px-4 ${ pathname === '/pricing' || pathname.includes('courses') || pathname === '/pricing/v2'  ? 'relative w-full max-w-[720px] mx-auto' : 'hidden'} text-white`}>
                <Line percent={width < 560 ? (pathname.includes('courses') ? 54 : (pathname === '/pricing' || pathname === '/pricing/v2') ? 88 : 0) : ( pathname.includes('courses') ? 60 : (pathname === '/pricing' || pathname === '/pricing/v2') ? 94 : 0)} strokeWidth={`${width >= 720 ? '1.5' : "2"}`} trailWidth={`${width >= 720 ? '1.5' : "2"}`} strokeColor="#7DDEFF" trailColor="#D9D9D9" />
                <div className={`absolute -top-2 ${width < 560 ? ( pathname.includes('courses') ? 'left-[54%] lg:left-[60%]' : (pathname === '/pricing' || pathname === '/pricing/v2' ) ? 'left-[84%]' : '') : ( pathname.includes('courses') ? 'left-[58%] lg:left-[59.5%]' : (pathname === '/pricing' || pathname === '/pricing/v2') ? 'left-[90%] md:left-[91%]' : '')}`}>
                    <img src="https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/location_1.svg?updatedAt=1686908182013" alt="" />
                </div>
                <div className='flex justify-between text-[10px] mt-5'>
                    <p>Assessment</p>
                    <p>Analysis</p>
                    <p>Course Generation</p>
                    <p>Course Unlock</p>
                </div>
            </div>
            <Outlet />
        </div>
  )
}

export default Layout