import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    skillName: "",
    skillLevel: "",
    skillDescription: "",
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    type: ""
}

export const fetchSkillName = createAsyncThunk(
    'fetchSkillName/skillDetailsSlice',
    async ({ skill_slug }) => {
        const response = await axios.get('https://xgfy-czuw-092q.m2.xano.io/api:ZZCtun0K/fetch_skill_name_for_personalize', {
            params: {
                skill_slug
            }
        })
        return response.data
    }
)

const skillDetailsSlice = createSlice({
    name: "skillDetails",
    initialState,
    reducers: {
        setUserSkillDetails: (state, action) => {
            const { skillName, skillLevel, skillDescription, utm_source, utm_medium, utm_campaign } = action.payload;
            state.skillName = skillName;
            state.skillLevel = skillLevel;
            state.skillDescription = skillDescription
            state.utm_source = utm_source
            state.utm_medium = utm_medium
            state.utm_campaign = utm_campaign
        },
        setUserSkillName: (state, action) => {
            const { skillName } = action.payload;
            console.log("this is inside reducer", skillName)
            state.skillName = skillName;
        },
        setUserSkill: (state, action) => {
            const { skillName, skillLevel, skillDescription } = action.payload
            state.skillName = skillName
            state.skillLevel = skillLevel;
            state.skillDescription = skillDescription
        },
        setUserSkillLevel: (state, action) => {
            const { skillLevel, skillDescription } = action.payload
            state.skillLevel = skillLevel
            state.skillDescription = skillDescription
        },
        setUTMDetails: (state, action) => {
            const { utm_source, utm_medium, utm_campaign, type } = action.payload
            state.utm_source = utm_source
            state.utm_campaign = utm_campaign
            state.utm_medium = utm_medium
            state.type = type
        }
    },
    extraReducers: (builder)=> {
        builder.addCase(fetchSkillName.fulfilled, (state, action) => {
            state.skillName = action?.payload?.['skill-name']
        })
    }

})

export const { setUserSkillName, setUserSkill, setUTMDetails, setUserSkillLevel, setUserSkillDetails } = skillDetailsSlice.actions
export const selectSkillName = (state) => state.skillDetails?.skillName
export const selectUserSkillDetails = (state) => state.skillDetails
export default skillDetailsSlice.reducer