import React from 'react'
import { PropagateLoader } from 'react-spinners'

const Loader = () => {
  return (
    <div className='flex-1 flex justify-center items-center' style={{background: 'radial-gradient(50% 50% at 50% 50%, #26496A 0%, #182C3F 100%)'}}>
        <PropagateLoader color='#7FC241' size={20}/>
    </div>
  )
}

export default Loader