import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

export const fetchBottomIllustationStatus = createAsyncThunk(
    'fetchBottomIllustationStatus/bottomIllustrationSlice',
    async ({ email }) => {
        const response = await axios.get('https://xgfy-czuw-092q.m2.xano.io/api:ZZCtun0K/user_account_status/banner', {
            params: {
                email: email
            }
        })

        return response.data
    }
)

const initialState = {
    show_banner: true
}

const bottomIllustrationSlice = createSlice({
    name: 'bottomIllustration',
    initialState,
    reducers: {
        setShowBanner: (state, action) => {
            state.show_banner = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchBottomIllustationStatus.fulfilled, (state, action) => {
            return action.payload
        })
    }
})

export const { setShowBanner } = bottomIllustrationSlice.actions
export const selectBottomIllustationStatus = (state) => state.bottomIllustration
export default bottomIllustrationSlice.reducer