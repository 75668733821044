import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosAuth } from "../config/axiosInstance";

export const fetchFeedback = createAsyncThunk(
    'feedback/fetchFeedback',
    async ({ user_id, subject, video_id, lesson_id, goal, question, skill, answer }) => {      
        try {
          const response = await axiosAuth.post('https://ninjacurate.learntube.ai/quiz_q_analysis', { user_id: user_id, subject: subject, video_id:video_id, lesson_id: lesson_id, goal:goal, skill:skill, question:question, answer:answer });
          return response.data;
        } catch (err) {
          console.log(err);
        }
  }
);


const feedbackSlice = createSlice({
    name: 'feedback',
    initialState: {},
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchFeedback.fulfilled, (state, action) => {
            return action.payload;
        });
    },
});

export const selectFeedback = (state) => state.feedback;
export default feedbackSlice.reducer;