import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosAuth } from "../config/axiosInstance";

export const fetchLessonQuiz = createAsyncThunk(
    'lessonQuiz/fetchLessonQuiz',
    async ({ user_id, video_id, subject, lesson_id, goal, skill }) => {      
        try {
            const response = await axiosAuth.post('https://ninjacurate.learntube.ai/quiz_q_gen', {user_id: user_id, video_id: video_id, subject: subject, goal: goal, lesson_id: lesson_id, skill:skill });
            return response.data;
        } catch (err) {
            console.log(err)
        }
    }
);

const initialState = {
    quiz: {},
    showQuizModal: false
}

const lessonQuizSlice = createSlice({
    name: 'lessonQuiz',
    initialState,
    reducers: {
        setShowQuizModal: (state, action) => {
            state.showQuizModal = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchLessonQuiz.fulfilled, (state, action) => {
            return action.payload
        })
    },
});

export const { setShowQuizModal } = lessonQuizSlice.actions
export const selectLessonQuiz = (state) => state.lessonQuiz?.quiz
export const selectModalStatus = (state) => state.lessonQuiz?.showQuizModal
export default lessonQuizSlice.reducer