import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { axiosAuth } from "../config/axiosInstance";

export const fetchCourseList = createAsyncThunk(
    'courseList/fetchCourseList',
    async ({ uid }) => {      
        try {
            const response = await axiosAuth.post('https://ninjacurate.learntube.ai/fetch_user_course_names', { uid: uid });
            return response.data;
        } catch (err) {
            console.log(err);
        }
  }
);

const updateCourseName = createAction('courseList/updateCourseName');

const courseListSlice = createSlice({
    name: 'courseList',
    initialState: {
        courseName: "",
        subject_names: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCourseList.fulfilled, (state, action) => {
            return {
                courseName: action?.payload?.subject_names[0],
                subject_names: action?.payload?.subject_names
            };
        });
        builder.addCase(updateCourseName, (state, action) => {
            state.courseName = action.payload;
        });
    },
});

export { updateCourseName }
export const selectCourseList = (state) => state.courseList;
export default courseListSlice.reducer;