import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

export const fetchCoursesList = createAsyncThunk(
    'fetchCoursesList/CourseAndSkillList',
    async () => {
        const response = await axios.get('https://xgfy-czuw-092q.m2.xano.io/api:ZZCtun0K/all_xano_course_names')
        return response.data
    }
)

export const fetchSkillList = createAsyncThunk(
    'fetchSkillList/CourseAndSkillList',
    async ({ course_slug }) => {
        const response = await axios.get('https://xgfy-czuw-092q.m2.xano.io/api:ZZCtun0K/skills_in_course_for_personalize', {
            params: {
                course_slug: course_slug
            }
        })

        return response.data
    }
)

export const fetchSearchedSkillList = createAsyncThunk(
    'fetchSearchedSkillList/CourseAndSkillList',
    async ({ value }) => {
        const response = await axios.get('https://xgfy-czuw-092q.m2.xano.io/api:ZZCtun0K/search_xano_course', {
            params: {
                search: value
            }
        })

        return response.data
    }
)




const initialState = {
    courses: [],
    skills: [],
    searchedSkills: [],
    searchLoading: false,
    courseLoading: false,
    skillLoading: false,
}

const courseAndSkillSlice = createSlice({
    name: 'courseAndSkill',
    initialState,
    reducers: {
        setSearchedSkill: (state, action) => {
            state.searchedSkills = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCoursesList.pending, (state) => {
            state.courseLoading = true
        })
        builder.addCase(fetchCoursesList.fulfilled, (state, action) => {
            state.courseLoading = false
            state.courses = action.payload
        })
        builder.addCase(fetchSkillList.pending, (state) => {
            state.skillLoading = true
        })
        builder.addCase(fetchSkillList.fulfilled, (state, action) => {
            state.skillLoading = false
            state.skills = action.payload
        })
        builder.addCase(fetchSearchedSkillList.pending, (state) => {
            state.searchLoading = true
        })
        builder.addCase(fetchSearchedSkillList.fulfilled, (state, action) => {
            state.searchLoading = false
            state.searchedSkills = action.payload
        })
        
    }
})

export const selectCourseAndSkillList = (state) => state.courseAndSkill
export const { setSearchedSkill } = courseAndSkillSlice.actions
export default courseAndSkillSlice.reducer